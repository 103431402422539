var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.url.length > 1 ? _c('swiper-container', {
    staticClass: "touch-items-wrapper tw-overflow-hidden",
    attrs: {
      "slides-per-view": "1",
      "navigation": "true",
      "navigation-prev-el": ".swiper-button-prev",
      "navigation-next-el": ".swiper-button-next"
    }
  }, [_c(_setup.MdiChevronLeftCircle, {
    staticClass: "swiper-button swiper-button-prev tw-absolute tw-size-6 tw-z-10 tw-left-0 tw-top-1/2 -tw-translate-y-1/2 tw-text-primary [&.swiper-button-disabled]:tw-hidden",
    attrs: {
      "slot": "container-start"
    },
    slot: "container-start"
  }), _vm._v(" "), _c(_setup.MdiChevronRightCircle, {
    staticClass: "swiper-button swiper-button-next tw-absolute tw-size-6 tw-z-10 tw-right-0 tw-top-1/2 -tw-translate-y-1/2 tw-text-primary [&.swiper-button-disabled]:tw-hidden",
    attrs: {
      "slot": "container-end"
    },
    slot: "container-end"
  }), _vm._v(" "), _vm._l(_setup.url, function (image, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('CoverImage', {
      class: _vm.imageClass,
      attrs: {
        "src": image
      }
    })], 1);
  })], 2) : _c('CoverImage', {
    class: _vm.imageClass,
    attrs: {
      "src": _setup.url[0],
      "dummy": _vm.dummy
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };