/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ProductDetailDialog.vue?vue&type=template&id=6ba3d057"
import script from "./ProductDetailDialog.vue?vue&type=script&lang=js"
export * from "./ProductDetailDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,CoverImages: require('/code/src/components/img/CoverImages.vue').default,LabelText: require('/code/src/components/text/LabelText.vue').default,SmallLabel: require('/code/src/components/text/SmallLabel.vue').default,TouchVariants: require('/code/src/components/widgets/TouchVariants.vue').default,CustomDialog: require('/code/src/components/dialog/CustomDialog.vue').default})
