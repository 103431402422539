import ProductDetailDialog from './ProductDetailDialog.vue';
export default {
  components: {
    ProductDetailDialog: ProductDetailDialog
  },
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    products: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickItem: function onClickItem(item) {
      this.$refs.dialog.show(item);
    }
  }
};