import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5, _vm$item6, _vm$item7;
  var _vm = this,
    _c = _vm._self._c;
  return _c('CustomDialog', {
    attrs: {
      "max-width": "800px",
      "negative": _vm.$t('action.cancel'),
      "positive": _vm.$t('action.createTouch')
    },
    on: {
      "click:positive": function clickPositive($event) {
        return _vm.$router.push("/touch/new/?id=".concat(_vm.item.product_id));
      }
    },
    scopedSlots: _vm._u([_vm.loading ? {
      key: "loading",
      fn: function fn() {
        return [_c('Loading')];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm._v(" "), _vm.item ? [_c('div', {
    staticClass: "two-columns"
  }, [_c('div', {
    staticClass: "left-column",
    staticStyle: {
      "width": "350px"
    }
  }, [_c('CoverImages', {
    staticClass: "ma-auto",
    attrs: {
      "src": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.images,
      "image-class": "!tw-max-h-[500px]",
      "dummy": !((_vm$item2 = _vm.item) !== null && _vm$item2 !== void 0 && _vm$item2.images)
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "right-column mb-4 mt-6 mt-md-4"
  }, [_c('LabelText', {
    attrs: {
      "label": _vm.$t('productName'),
      "text": _vm.item.product_title
    }
  }), _vm._v(" "), _c('LabelText', {
    staticClass: "mt-2",
    attrs: {
      "label": _vm.$t('cost'),
      "text": _vm.$price(_vm.item.product_price)
    }
  }), _vm._v(" "), _c('LabelText', {
    staticClass: "mt-2",
    attrs: {
      "label": _vm.$t('description')
    }
  }, [(_vm$item3 = _vm.item) !== null && _vm$item3 !== void 0 && _vm$item3.description_html ? _c('div', {
    staticClass: "text-body-1",
    domProps: {
      "innerHTML": _vm._s((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.description_html)
    }
  }) : _vm._e()]), _vm._v(" "), ((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.tags.length) > 0 ? [_c('SmallLabel', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('marketplace.option')
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-2"
  }), _vm._v(" "), _vm._l((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.tags, function (tag) {
    return _c('div', {
      key: tag,
      staticClass: "text-subtitle-2",
      domProps: {
        "textContent": _vm._s("\u30FB".concat(tag))
      }
    });
  })] : _vm._e()], 2)]), _vm._v(" "), _c('SmallLabel', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('variants')
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-2"
  }), _vm._v(" "), _c('TouchVariants', {
    staticClass: "tw-pointer-events-none",
    attrs: {
      "variants": (_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.variants
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };