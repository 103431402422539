import { VDataIterator } from 'vuetify/lib/components/VDataIterator';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p-relative"
  }, [_vm.loading || _vm.products == null ? _c('div', {
    staticClass: "items-wrapper"
  }, [_vm._l(10, function (n) {
    return _c('div', {
      key: n,
      staticClass: "item"
    }, [_c('ProductDummyCard')], 1);
  }), _vm._v(" "), _vm._l(10, function (n) {
    return _c('div', {
      key: "spacer-".concat(n),
      staticClass: "item item--spacer"
    });
  })], 2) : _c(VDataIterator, {
    attrs: {
      "items": _vm.products || [],
      "item-key": "id",
      "options": _vm.options,
      "hide-default-footer": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$emit('update:options', $event);
      },
      "update:page": function updatePage($event) {
        _vm.$el.getBoundingClientRect().top < 0 && _vm.$el.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('div', {
          staticClass: "items-wrapper"
        }, [_vm._l(items, function (item) {
          return _c('div', {
            key: item.id,
            staticClass: "item"
          }, [_c('ProductCard', {
            attrs: {
              "item": item
            },
            on: {
              "click": _vm.onClickItem
            }
          })], 1);
        }), _vm._v(" "), _vm._l(10, function (n) {
          return _c('div', {
            key: "spacer-".concat(n),
            staticClass: "item item--spacer"
          });
        })], 2)];
      }
    }, {
      key: "footer",
      fn: function fn(_ref2) {
        var pagination = _ref2.pagination,
          opt = _ref2.options,
          updateOptions = _ref2.updateOptions;
        return [_c('CustomVDataFooter', {
          attrs: {
            "pagination": pagination,
            "options": opt,
            "items-per-page-options": [10, 25, 50, 100]
          },
          on: {
            "update:options": updateOptions
          }
        })];
      }
    }])
  }), _vm._v(" "), _c('ProductDetailDialog', {
    ref: "dialog"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };