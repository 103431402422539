/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./CoverImages.vue?vue&type=template&id=4c3ac017"
import script from "./CoverImages.vue?vue&type=script&lang=ts&setup=true"
export * from "./CoverImages.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoverImage: require('/code/src/components/img/CoverImage.vue').default})
